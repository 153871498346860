import { useLocation, useNavigate } from '@remix-run/react'
import { useMemo } from 'react'
import {
  aCheckedListings,
  aSelectedListing,
  aUser,
  useAtom,
  useAtomValue,
} from '~/atoms'
import { ListingCardBase } from '~/components'
import { MapView, useCurrentMapView, useMapListingLink } from '~/hooks'
import { type Listing } from '~/models'

type MapListingCardProps = {
  className?: string
  listing: Listing
  onMouseOver?: () => void
  onMouseOut?: () => void
  showOriginalAttachments?: boolean
  showOriginalBrokers?: boolean
}

export default function MapListingCard(props: MapListingCardProps) {
  const {
    className,
    listing,
    onMouseOver,
    onMouseOut,
    showOriginalAttachments = false,
    showOriginalBrokers = false,
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAtomValue(aUser)
  const [_, setSelectedListing] = useAtom(aSelectedListing)
  const currentMapView = useCurrentMapView()
  const [checkedListings, setCheckedListings] = useAtom(aCheckedListings)

  const isReportPage = useMemo(
    () => location.pathname.includes('/report'),
    [location]
  )
  const listingLink = useMapListingLink(listing)

  const link = useMemo(() => {
    if (isReportPage) {
      let link = `${listingLink}?report=true`
      if (showOriginalAttachments) {
        link += '&attachments=true'
      }
      if (showOriginalBrokers) {
        link += '&brokers=true'
      }
      return link
    }
    return listingLink
  }, [listingLink, showOriginalAttachments, showOriginalBrokers, isReportPage])

  return (
    <ListingCardBase
      className={className}
      listing={listing}
      link={link}
      lazyLoadImage={!isReportPage}
      showCompany={!isReportPage || showOriginalBrokers}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={(e) => {
        if (
          isReportPage &&
          location.pathname.includes('/map') &&
          e.metaKey === false &&
          e.ctrlKey === false
        ) {
          setSelectedListing(listing)
          e.preventDefault()
          e.stopPropagation()
        } else if (isReportPage) {
          window.open(link, '_blank')
        } else if (
          currentMapView == MapView.Map &&
          e.metaKey === false &&
          e.ctrlKey === false
        ) {
          navigate(`/${listing.slug}${location.search}`)
          setSelectedListing(listing)
          e.preventDefault()
          e.stopPropagation()
        } else if (e.metaKey || e.ctrlKey || currentMapView === MapView.Cards) {
          // if (isReportPage) {
          //   window.open(`${listingLink}?report=true`, '_blank')
          // } else {
          //   window.open(`${listingLink}`, '_blank')
          // }
          window.open(link, '_blank')
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      checked={checkedListings.includes(listing._id)}
      onCheckChanged={
        !user || isReportPage
          ? undefined
          : () => {
              if (checkedListings.includes(listing._id)) {
                setCheckedListings(
                  checkedListings.filter((id) => id !== listing._id)
                )
              } else {
                setCheckedListings([...checkedListings, listing._id])
              }
            }
      }
    />
  )
}
