import { useLocation } from '@remix-run/react'
import { useMemo } from 'react'

export enum MapView {
  Map,
  Cards,
  Layers,
  Data,
  Stats,
}

export function useCurrentMapView() {
  const location = useLocation()
  const currentMapView = useMemo(() => {
    if (location.pathname === '/cards') {
      return MapView.Cards
    } else if (location.pathname === '/layers') {
      return MapView.Layers
    }
    return MapView.Map
  }, [location.pathname])

  return currentMapView
}
